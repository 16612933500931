import http from "../axios/http"

//获取客户类型
export let getList = (params) => {
    return http.get("/crm/type_index", { params })
}
//增加客户类型
export let postAdd = (params) => {
    return http.post("/crm/type_insert", params)
}
//更新客户类型
export let postUpdate = (params) => {
    return http.post("/crm/type_update", params)
}
//更新客户类型
export let postDel = (params) => {
    return http.post("/crm/type_delete", params)
}
