<template>
  <div class="CustomType">
    <a-button type="primary" @click="handleAdd">添加</a-button>
    <a-table
      :columns="columns"
      :data-source="data"
      rowKey="id"
      style="margin-top: 15px"
    >
      <template #operation="{ record }">
        <a-button type="primary" size="small" @click="handleEdit(record)"
          >编辑</a-button
        >
        <a-popconfirm
          title="Are you sure delete this task?"
          ok-text="Yes"
          cancel-text="No"
          @confirm="handleDel(record.id)"
        >
          <a-button size="small">删除</a-button>
        </a-popconfirm>
      </template>
    </a-table>
    <a-modal
      v-model:visible="visible"
      :title="id ? '编辑客户类型' : '添加客户类型'"
      centered
      :maskClosable="false"
      :width="500"
      class="CustomType"
      @ok="okAdd"
      @cancel="formRest"
    >
      <a-form class="form" :model="formState">
        <a-form-item label="客户类型" required>
          <a-input placeholder="请输入" v-model:value="formState.value" />
        </a-form-item>
        <a-form-item label="按键">
          <a-input placeholder="请输入" v-model:value="formState.button" />
        </a-form-item>
        <a-form-item label="排序">
          <a-input placeholder="请输入" v-model:value="formState.sort" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import {
  getList,
  postAdd,
  postUpdate,
  postDel,
} from "../../service/customtype";
import { onMounted, reactive, toRefs } from "vue";
import { message } from "ant-design-vue";
const columns = [
  {
    title: "ID",
    dataIndex: "id",
  },
  {
    title: "客户类型",
    dataIndex: "value",
  },
  {
    title: "按键",
    dataIndex: "button",
  },
  {
    title: "排序",
    dataIndex: "sort",
  },
  {
    title: "操作",
    fixed: "right",
    dataIndex: "operation",
    slots: {
      customRender: "operation",
    },
  },
];
export default {
  name: "CustomType",
  setup() {
    const state = reactive({
      visible: false,
      data: [],
      id: undefined,
      arr: ["button", "sort", "value"],
      page: 1,
      total: undefined,
    });
    const formState = reactive({
      value: undefined,
      button: undefined,
      sort: undefined,
    });
    const formRest = () => {
      formState.value = undefined;
      formState.button = undefined;
      formState.sort = undefined;
      state.id = undefined;
    };
    onMounted(() => {
      getListFun();
    });
    const getListFun = async () => {
      const res = await getList({
        access_token: sessionStorage.getItem("token"),
        page: state.page,
        limit: 10,
      });
      console.log(res);
      state.data = res.data.data.list;
      state.total = res.data.data.total;
    };
    const handleAdd = () => {
      state.visible = true;
    };
    const handleEdit = (record) => {
      state.visible = true;
      state.id = record.id;
      console.log(record);
      for (let key in record) {
        if (state.arr.includes(key)) {
          formState[key] = record[key];
        }
      }
    };
    const okAdd = async () => {
      const params = {};
      for (let key in formState) {
        params[key] = formState[key];
      }
      if (state.id) {
        params.id = state.id;
        const res = await postUpdate({
          access_token: sessionStorage.getItem("token"),
          ...params,
        });
        console.log(res);
        if (res.data.status == 100000) {
          message.success(res.data.msg);
          state.visible = false;
          formRest();
          getListFun();
        } else {
          message.error(res.data.msg);
        }
      } else {
        const res = await postAdd({
          access_token: sessionStorage.getItem("token"),
          ...params,
        });
        console.log(res);
        if (res.data.status == 100000) {
          message.success(res.data.msg);
          state.visible = false;
          formRest();
          getListFun();
        } else {
          message.error(res.data.msg);
        }
      }
    };
    const handleDel = async (id) => {
      const res = await postDel({
        access_token: sessionStorage.getItem("token"),
        id,
      });
      console.log(res);
      if (res.data.status == 100000) {
        message.success(res.data.msg);
        getListFun();
      } else {
        message.error(res.data.msg);
      }
    };
    return {
      ...toRefs(state),
      columns,
      handleAdd,
      handleEdit,
      formState,
      okAdd,
      formRest,
      handleDel,
    };
  },
};
</script>
<style >
.CustomType .form .ant-form-item-label {
  min-width: 105px;
}
</style>
<style scoped>
.CustomType {
  height: 100%;
  background-color: #fff;
  padding: 15px;
}
.ant-btn-primary:nth-child(1) {
  margin-right: 6px;
}
.form {
  display: flex;
  flex-wrap: wrap;
}
.form .ant-form-item {
  width: 360px;
}
.form .ant-form-item:nth-child(2n + 1) {
  margin-right: 30px;
}
</style>